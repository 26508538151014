import React, { useEffect } from "react"
import { useParams } from "react-router-dom";
import useBodyClass from "../../Hooks/useBodyClass";
import cn from 'classnames';
import css from './ThankYouPage.module.scss';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ThankYouPage: React.FC = () => {

    const { itemId } = useParams();

    useBodyClass("show-section");


    useEffect(() => {
        console.log("init");
        console.log(itemId);

    }, [itemId]);

    return (
        <>
            <section className={cn(css.thankyouPage, "thankyou-circle", "steps-inner")}>
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className={css.thankyouPageInner}>
                            <div className={cn("wrapper", css.wrapper)}>

                                <div className={cn(css.tick, "tick-icon")}>
                                    <div className={css.doneTick}></div>
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>

                                <h2>Thank you for your reply</h2>

                                {/* <div className="next-prev-btn">
                                    <button className="next back">Back Home</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
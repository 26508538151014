import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useParams } from 'react-router-dom';
import { load } from '../../Logic/AWSQuestionsDataServive';
import { IQuestionData } from '../../Models/IQuestionData';
import css from './ResultPage.module.scss';
import cn from 'classnames';
import useBodyClass from '../../Hooks/useBodyClass';

export const ResultPage: React.FC = () => {
    const { itemId } = useParams();
    const [question, setQuestion] = useState<IQuestionData>();
    const [data, setData] = useState<any[]>([]);
    const [totVotes, setTotVotes] = useState<number>(0);

    useBodyClass(css.bodyBg);

    useEffect(() => {
        if (itemId) {
            (async () => {
                let q = await load(itemId);
                setQuestion(q);
            })();

            const interval = setInterval(() => {
                (async () => {
                    let tmp = await load(itemId);
                    setQuestion(tmp);
                })();
            }, 2000);

            return () => {
                if (interval) clearInterval(interval);
            };
        }
    }, [itemId]);

    useEffect(() => {
        if (question) {
            let tmp = Object.entries(question.answers).map(([key, ans]) => {
                return { text: ans.index, votes: ans.Votes };
            });

            tmp = _.orderBy(tmp, 'text');
            setTotVotes(_.sumBy(tmp, 'votes'));

            setData([['Answer', 'Vote'], ...tmp.map(Object.values)]);
        }
    }, [question]);

    const options = {
        title: '',
        legend: 'bottom',
        colors: ['#003B79', '#B0CEEC', '#EE7D11', '#D9E021', '#DDDDDD'],
        backgroundColor: 'transparent',
    };

    /*
        Blu: 
            #003B79 - R0 G50 B121
        Azzurro: 
            #B0CEEC - R176 G206 B236
        Arancio:
            #EE7D11 - R238 G125 B17
        Giallo:
            #D9E021 - R217 G224 B33
        Grigio:
            #DDDDDD - R221 G221 B221
    */

    const grafh = () => {
        return <Chart chartType="PieChart" data={data} options={options} width={'100%'} height={'100%'} />;
    };

    const noVotes = () => {
        return <h3>No votes</h3>;
    };

    return (
        <>
            <div className="container py-1">
                <div className={cn(css.rowTitle, 'd-flex justify-content-center align-items-center')}>
                    {question && (
                        <h1 className={css.questionTitle}>
                            <span>{('00' + question?.index).slice(-2)}</span>
                            {question?.text}
                        </h1>
                    )}
                </div>

                <div className={cn(css.rowBody, 'd-flex justify-content-center align-items-center')}>
                    {question && data && totVotes ? grafh() : noVotes()}
                </div>
            </div>
        </>
    );
};

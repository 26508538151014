import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { getAll } from '../../Logic/AWSQuestionsDataServive';
import { IQuestionData } from '../../Models/IQuestionData';

export const ListQuestionPage: React.FC = () => {
    const [questions, setQuestions] = useState<IQuestionData[]>([]);

    useEffect(() => {
        (async () => {
            let tmp = await getAll();
            setQuestions(_.orderBy(tmp,"index"));
        })();
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-6 offset-3">
                        <h2>Lista delle domande</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 offset-3">
                        {questions && (
                            <div className="list-group">
                                {questions.map((q) => {
                                    return (
                                        <div key={q.questionId} className="list-group-item">
                                            {q.index}.&nbsp;{q.text}
                                            <div className="m-0 p-0 ps-3">
                                                <div className="float-end">
                                                    <img
                                                        src={q.backgroud}
                                                        style={{ width: '120px', maxHeight: '120px' }}
                                                        alt=""
                                                    />
                                                </div>
                                                Question: <br />
                                                <span>
                                                    {`/quiz/${q.questionId}`}
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`/quiz/${q.questionId}`);
                                                        }}
                                                    >
                                                        copy
                                                    </button>
                                                </span>
                                                <br />
                                                Result: <br />
                                                <span>{`/quiz/${q.questionId}/result`}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

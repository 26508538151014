import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { currentUserReducer } from "./CurrenUser/current-user.slice";

const rootReducer = combineReducers({
    CurrenUser: currentUserReducer
  });
  
  
  
  export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "development"
  });
  
  
  // NOTE: definire il tipo dello store partendo del reducer
  export type IRootState = ReturnType<typeof rootReducer>;
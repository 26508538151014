import AWS from 'aws-sdk';
import { DocumentClient, UpdateItemInput } from 'aws-sdk/clients/dynamodb';
import { IQuestionVote } from '../Models/IQuestionVote';
import awsConfig from './aws.setting';
import dynamoTables from './aws.dynamo_table';
import { IQuestionData } from '../Models/IQuestionData';

AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

// const findByIdUser = async (idUser: number) => {

//     let params: DocumentClient.ScanInput = {
//         TableName: TB_NAME,
//         ExpressionAttributeValues: { ':idU': idUser },
//         FilterExpression: 'iduser = :idU',

//     };

//     let d = await docClient.scan(params).promise()
//     return (d.Items as IPeopleChoiceVote[])[0];
// };

const addVote = async (model: IQuestionVote) => {
    let transItem: AWS.DynamoDB.DocumentClient.TransactWriteItemList = [];

    transItem.push({
        Put: {
            // Write an item to the Cart table
            Item: model,
            TableName: dynamoTables.T_Answers,
        },
    });

    // response=tb_pages.update_item(
    //     Key={
    //             'idPages':item['idPages'],
    //             'url':item['url']
    //             },
    //     UpdateExpression="SET var1InTable= :var1, var2InTable= :var2,var3InTable= :var3",
    //     AttributeUpdates={
    //             ':var1': var1String,
    //             ':var2': var2StrList,
    //             ':var3': var3NewString
    //             },
    //     ReturnValues="UPDATED_NEW"

    //         )

    let expAttName: AWS.DynamoDB.DocumentClient.ExpressionAttributeNameMap = {};
    let updExpression: string[] = [];
    expAttName['#answers'] = 'answers';
    expAttName['#votes'] = 'Votes';

    model.answersId.forEach((answerId, index) => {
        updExpression.push(`#answers.#answerId${index}.#votes = #answers.#answerId${index}.#votes + :votes_inc`);
        expAttName['#answerId' + index] = answerId;
    });

    transItem.push({
        Update: {
            // Decrement count of items available to buy only if the count is greater than zero
            TableName: dynamoTables.T_Questions,
            Key: {
                questionId: model.questionId,
            },
            UpdateExpression: 'SET ' + updExpression.join(', '),
            // For consistency, always use ExpressionAttributeNames for all attributes
            ExpressionAttributeNames: expAttName,
            ExpressionAttributeValues: {
                ':votes_inc': 1,
            },
        },
    });

    console.log(transItem);

    try {
        await docClient
            .transactWrite({
                TransactItems: transItem,
            })
            .promise();
    } catch (error) {
        console.log(error);
        debugger;

        return null;
    }
};

const removeVote = async (idvote: string) => {
    try {
        let paramSearch: DocumentClient.DeleteItemInput = {
            TableName: dynamoTables.T_Answers,
            Key: {
                idvotes: idvote,
            },
        };

        await docClient.delete(paramSearch).promise();

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};


const resetAllVotes = async () => {
    // non ci preoccupiamo della concorrenza e dell'efficienza, è un'operazione da fare da pannello
    try {


        // questions
        const questionsResponse = await docClient.scan({ TableName: dynamoTables.T_Questions }).promise();
        let questions: IQuestionData[] = [];
        if (questionsResponse.Items) {
            questions = questionsResponse.Items as IQuestionData[];
        }
        // update questions: non vanno cancellate le questions, ma vanno resettate con update
        for (const singleQuestion of questions) {
            let expAttName: AWS.DynamoDB.DocumentClient.ExpressionAttributeNameMap = {};
            let updExpression: string[] = [];
            expAttName['#answers'] = 'answers';
            expAttName['#votes'] = 'Votes';

            for (const singleAnswer in singleQuestion.answers) {
                updExpression.push(`#answers.#answerId${singleAnswer}.#votes = :votes_reset`);
                expAttName['#answerId' + singleAnswer] = singleAnswer;
            }

            const paramUpdateAnswers: DocumentClient.UpdateItemInput = {
                TableName: dynamoTables.T_Questions,
                Key: {
                    questionId: singleQuestion.questionId,
                },
                UpdateExpression: 'SET ' + updExpression.join(', '),
                ExpressionAttributeNames: expAttName,
                ExpressionAttributeValues: {
                    ':votes_reset': 0,
                },
            };
            await docClient.update(paramUpdateAnswers).promise();
        }

        // answers
        let answerResponse, ExclusiveStartKey;
        let accumulated: IQuestionVote[] = [];

        do {
            answerResponse = await docClient
                .scan({
                    TableName: dynamoTables.T_Answers,
                    ExclusiveStartKey,
                })
                .promise();

            ExclusiveStartKey = answerResponse.LastEvaluatedKey;

            if (answerResponse.Items?.length) {
                accumulated = [...(accumulated as IQuestionVote[]), ...(answerResponse.Items as IQuestionVote[])];
            }
        } while (answerResponse.LastEvaluatedKey);

        // cancellazione voti su accumulated
        if (accumulated) {
            for (const singleVote of accumulated) {
                let paramDeleteVote: DocumentClient.DeleteItemInput = {
                    TableName: dynamoTables.T_Answers,
                    Key: {
                        questionId: singleVote.questionId,
                        userKey: singleVote.userKey,
                    },
                };
                await docClient.delete(paramDeleteVote).promise();
            }
        }
    } catch (error) {
        console.log(error);
        return false;
    }

    return true;
};

const resetQuestionVotes = async (questionId: string) => {
    // non ci preoccupiamo della concorrenza e dell'efficienza, è un'operazione da fare da pannello
    try {

        // Recupero la domanda
        let params: DocumentClient.GetItemInput = {
            TableName: dynamoTables.T_Questions,
            Key: {
                "questionId": questionId
            }
        };

        let myQuestion = await docClient.get(params).promise()

        if (!myQuestion.Item) throw new Error('question not found');

        // update questions: non vanno cancellate le questions, ma vanno resettate con update
        let expAttName: AWS.DynamoDB.DocumentClient.ExpressionAttributeNameMap = {};
        let updExpression: string[] = [];
        expAttName['#answers'] = 'answers';
        expAttName['#votes'] = 'Votes';

        for (const singleAnswer in myQuestion.Item.answers) {
            updExpression.push(`#answers.#answerId${singleAnswer}.#votes = :votes_reset`);
            expAttName['#answerId' + singleAnswer] = singleAnswer;
        }

        const paramUpdateAnswers: DocumentClient.UpdateItemInput = {
            TableName: dynamoTables.T_Questions,
            Key: {
                questionId: myQuestion.Item.questionId,
            },
            UpdateExpression: 'SET ' + updExpression.join(', '),
            ExpressionAttributeNames: expAttName,
            ExpressionAttributeValues: {
                ':votes_reset': 0,
            },
        };
        await docClient.update(paramUpdateAnswers).promise();

        // recupero dei results
        let answerResponse, ExclusiveStartKey;
        let accumulated: IQuestionVote[] = [];

        do {
            answerResponse = await docClient
                .query({
                    TableName: dynamoTables.T_Answers,
                    KeyConditionExpression: "#DDB_questionId = :pkey", 
                    ExpressionAttributeNames: {
                      "#DDB_questionId": "questionId",
                    }, 
                    ExpressionAttributeValues: {
                      ":pkey": myQuestion.Item.questionId,
                    },
                    ExclusiveStartKey,
                })
                .promise();

            ExclusiveStartKey = answerResponse.LastEvaluatedKey;

            if (answerResponse.Items?.length) {
                accumulated = [...(accumulated as IQuestionVote[]), ...(answerResponse.Items as IQuestionVote[])];
            }
        } while (answerResponse.LastEvaluatedKey);

        // cancellazione voti su accumulated
        if (accumulated) {
            for (const singleVote of accumulated) {
                let paramDeleteVote: DocumentClient.DeleteItemInput = {
                    TableName: dynamoTables.T_Answers,
                    Key: {
                        questionId: singleVote.questionId,
                        userKey: singleVote.userKey,
                    },
                };
                await docClient.delete(paramDeleteVote).promise();
            }
        }
    } catch (error) {

        console.error(error);
        return false;
    }

    return true;
};

export { addVote, removeVote, resetQuestionVotes, resetAllVotes };

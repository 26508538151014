import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

type TPieChartProps = {
    data: { text: string; votes: number }[];
    title: string;
};

//

export const PieChart: React.FC<TPieChartProps> = (props) => {
    // const data = {
    //     datasets: [{
    //         data: [{x: 10, y: 20}, {x: 15, y: null}, {x: 20, y: 10}]
    //     }]
    // }

    const [data, setData] = useState<any>([]);
    //const [totVotes, setTotVotes] = useState<number>(0);

    useEffect(() => {

        let tmp = _.orderBy(props.data, "text");

        setData([['Answer', 'Vote'], ...tmp.map(Object.values)]);
       
        //setTotVotes(_.sumBy(props.data, 'votes'));
        //console.log(JSON.stringify(data));
    }, [props.data]);

    const options = {
        title: '',
        legend: 'bottom',
        colors: ['#003B79', '#B0CEEC', '#EE7D11', '#D9E021', '#DDDDDD'],
        backgroundColor: 'transparent',
    };

    return (
        <>
            <Chart chartType="PieChart" data={data} options={options} width={'100%'} height={'600px'} />
        </>
    );
};

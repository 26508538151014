import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICurrenUserState {
    IdEvtUser: number | null,
    UserKey: string | null
}

const initialState: ICurrenUserState = {
    IdEvtUser: null,
    UserKey: null
}

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<number>) => {

            if (action.payload)
                state.IdEvtUser = action.payload;
            else
                state.IdEvtUser = null;
                
        },
        setUserKey: (state, action: PayloadAction<string>) => {

            if (action.payload)
                state.UserKey = action.payload;
            else
                state.UserKey = null;
                
        },
        reset: state => {
            state.IdEvtUser = null;
            state.UserKey = null
        }

    }

})

//Esporto le action
export const { setUserId, setUserKey, reset } = currentUserSlice.actions;

//Esport il reducer
export const currentUserReducer = currentUserSlice.reducer;
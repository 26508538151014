import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { IQuestionData } from "../Models/IQuestionData";
import dynamoTables  from './aws.dynamo_table';

import awsConfig from "./aws.setting";


AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

const getAll = async () => {
    let d = await docClient.scan({ TableName: dynamoTables.T_Questions }).promise()
    return d.Items as IQuestionData[];
};

const load = async (id: string) => {

    let params: DocumentClient.GetItemInput = {
        TableName: dynamoTables.T_Questions,
        Key: {
            "questionId": id
        }
    };

    let d = await docClient.get(params).promise()
    return d.Item as IQuestionData;

};

export {
    getAll,
    load
}
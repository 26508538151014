import React, { useEffect, useState } from 'react';
import { IAnswer } from '../../../Models/IQuestionData';
import cn from 'classnames';
import css from './AnswersList.module.scss';
import _ from 'lodash';

type TAnswersListProps = {
    answersList: { [key: string]: IAnswer };
    mode: 'radio' | 'checkbox';
    showResult: boolean;
    onChange: (answers: string[]) => void;
};
 

export const AnswersList: React.FC<TAnswersListProps> = (props) => {
    const [currentAnswer, setCurrentAnswer] = useState<string[]>([]);
    const [answersList, setAnswersList] = useState<(IAnswer & {answerId: string})[]>([]);

    useEffect(() => {

        let tmp = Object.entries(props.answersList).map(([key, ans]) => ({answerId: key, ...ans}));
        setAnswersList(_.orderBy(tmp, ["index"]));

    }, [props.answersList]);

    const toggleValueHandler = (e: React.MouseEvent<HTMLInputElement>) => {
        let curr = e.currentTarget.value;

        let arr: string[] = [];

        if (props.mode === 'radio') {
            arr = [curr];
        } else {
            arr = currentAnswer.includes(curr)
                ? currentAnswer.filter((i) => i !== curr) // remove item
                : [...currentAnswer, curr];
        }
        setCurrentAnswer(arr);
        props.onChange(arr);
    };

    return (
        <>
            <div className={cn(css.formInner, { [css.showResult]: props.showResult })}>
                {answersList.map((ans) => {
                    return (
                        <label
                            key={ans.answerId}
                            className={cn(
                                css.formInput,
                                `${currentAnswer?.includes(ans.answerId) ? css.activeInput : ''}`,
                                { [css.isCorrect]: ans.isCorrect },
                                { [css.isError]: !ans.isCorrect }
                            )}
                        >
                            <input type={props.mode} name="answers" onClick={toggleValueHandler} defaultValue={ans.answerId} />
                            <span className={css.questionIndex}>{ans.index}.&nbsp;</span>
                            <span className={css.questionText}>{ans.text}</span>
                        </label>
                    );
                })}
            </div>
        </>
    );
};

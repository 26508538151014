import { faChartPie, faCogs, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAll } from '../../Logic/AWSQuestionsDataServive';
import { IQuestionData } from '../../Models/IQuestionData';
import _ from 'lodash';
import { PieChart } from './Components/PieChart';
import cn from 'classnames';
import css from './ControlPanelPage.module.scss';
import { ResetButton } from './Components/ResetButton';

export const ControlPanelPage: React.FC = () => {
    const [questions, setQuestions] = useState<IQuestionData[]>([]);

    const [selectedQuestion, setSelectedQuestion] = useState<IQuestionData>();
    const [selectedQuestionId, setSelectedQuestionId] = useState<string>();

    useEffect(() => {
        (async () => {
            let tmp = await getAll();
            setQuestions(_.orderBy(tmp, 'index'));
        })();

        const interval = setInterval(() => {
            (async () => {
                let tmp = await getAll();
                setQuestions(_.orderBy(tmp, 'index'));
            })();
        }, 3000);

        return () => {
            if (interval) clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (questions && selectedQuestionId) {
            setSelectedQuestion(questions.find((x) => x.questionId === selectedQuestionId));
        }
    }, [questions, selectedQuestionId]);

    return (
        <>
            <div className="container py-3">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                            <span className="fs-4">Gamification CrifDay2022 - Control panel</span>
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto"></nav>
                    </div>
                </header>
                <main>
                    <div className="row mb-3">
                        <div className="col">
                            <table className={cn('table', css.tableAnswers)}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Question</th>
                                        <th scope="col" className="text-center">
                                            Votes
                                        </th>
                                        <th scope="col" className="text-center">
                                            <FontAwesomeIcon icon={faCogs} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questions &&
                                        questions.map((q, index) => {
                                            return (
                                                <tr
                                                    key={q.questionId}
                                                    className={cn({
                                                        [css.trSelected]: q.questionId === selectedQuestionId,
                                                    })}
                                                    onClick={() => setSelectedQuestionId(q.questionId)}
                                                >
                                                    <th scope="row">{q.index}</th>
                                                    <td>{q.text}</td>
                                                    <td className="text-center">
                                                        {_.sumBy(Object.values(q.answers), function (o) {
                                                            return o.Votes;
                                                        })}
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            to={`/quiz/${q.questionId}`}
                                                            target="_blank"
                                                            className="btn btn-info mx-1"
                                                        >
                                                            <FontAwesomeIcon icon={faLink} />
                                                        </Link>
                                                        <Link
                                                            to={`/quiz/${q.questionId}/result`}
                                                            target="_blank"
                                                            className="btn btn-success mx-1"
                                                        >
                                                            <FontAwesomeIcon icon={faChartPie} />
                                                        </Link>
                                                        <ResetButton questionId={q.questionId} questionText={q.text} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {selectedQuestion && (
                        <div className="row mb-3">
                            <div className={cn('col', css.graphBg)}>
                                <PieChart
                                    title={selectedQuestion.text}
                                    data={Object.entries(selectedQuestion.answers).map(([key, ans]) => {
                                        return { text: ans.text, votes: ans.Votes };
                                    })}
                                />
                            </div>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import cn from 'classnames';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { load } from "../../Logic/AWSQuestionsDataServive";
import { addVote } from "../../Logic/AWSQuestionsVoteServive";
import { IQuestionData } from "../../Models/IQuestionData";
//import { Timer } from "../../Shared/Timer/Timer";
import { AnswersList } from "./Components/AnswersList";
import { useSelector } from "react-redux";
import { IRootState } from "../../data/store";
import { ICurrenUserState } from "../../data/CurrenUser/current-user.slice";
import { v4 } from 'uuid';
import css from './QuestionPage.module.scss';

export const QuestionPage: React.FC = () => {

    const { itemId } = useParams();
    const user = useSelector<IRootState, ICurrenUserState>((state) => state.CurrenUser);

    const [question, setQuestion] = useState<IQuestionData>();
    const [currentAnswer, setCurrentAnswer] = useState<string[]>([]);
    const [isAnswer, setIsAnswer] = useState<boolean>(false);

    useEffect(() => {
        if (itemId) {
            (async () => {
                let q = await load(itemId);
                setQuestion(q);
            })();
        }

    }, [itemId]);

    const onSelectedAnswerHandler = (ans: string[]) => {
        setCurrentAnswer(ans);
    }

    const sendVote = () => {
        if (question && currentAnswer.length > 0) {
            let uk = user.UserKey || v4();
            addVote({ userKey: uk, questionId: question?.questionId, answersId: currentAnswer })
        }
        setIsAnswer(true);
    }

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        sendVote();
    }

    // const endTimerHandler = () => {
    //     console.log("timer is ended");
    //     sendVote();
    // }

    return (
        <>

            <section className={css.steps} style={{backgroundImage: `url(${question?.backgroud})`}}>
                <div className="container">
                    <div className="mx-auto col-md-12 col-lg-7">
                        <section className={cn(css.stepsInner, "pop-slide")} >
                            <div className={css.wrapper}>
                                <div className={css.stepHeading}>
                                    <h3 className="text-center">{question?.text}</h3>
                                    {/* <p>Fill out this quiz for fun!</p> */}
                                </div>
                                <div>
                                    {question &&
                                        <AnswersList
                                            answersList={question.answers}
                                            mode={question.mode === "SINGLE" ? "radio" : "checkbox"}
                                            showResult={isAnswer}
                                            onChange={onSelectedAnswerHandler} />
                                    }

                                    {!isAnswer &&
                                        <div className={css.formButtons}>
                                            <button
                                                type="button"
                                                className={css.next}
                                                onClick={onClickHandler}
                                                disabled={currentAnswer.length <= 0}
                                            >
                                                Send <FontAwesomeIcon icon={faArrowRight} />
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>

                    </div>
                </div >
            </section >
            {/* {!isAnswer && <Timer timer={10} endTimer={endTimerHandler} />} */}
        </>
    );
}
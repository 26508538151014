import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { setUserId, setUserKey } from './data/CurrenUser/current-user.slice';
import { ControlPanelPage } from './Pages/ControlPanel/ControlPanelPage';
import { ListQuestionPage } from './Pages/ListQuestion/ListQuestionPage';
import { QuestionPage } from './Pages/Question/QuestionPage';
import { ThankYouPage } from './Pages/Question/ThankYouPage';
import { useDispatch } from 'react-redux';
import { ResultPage } from './Pages/Question/ResultPage';

function App() {


  //https://surveys.digivents.net/Survey.html?IdEvento=1105&UK=MTEwNV83NjA1MjJfNjM3MjYxNjM1NDgwMDAwMDAw&ID_S=t3uxORgynT
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch = useDispatch();
  let idUser: number = 0;

  if (urlParams.has('DIGIVENTS_LOGGED_USER_ID')) {
    idUser = parseInt(urlParams.get('DIGIVENTS_LOGGED_USER_ID') as string);
    console.log(idUser);
    dispatch(setUserId(idUser));
  }

  let uk: string = "";
  if (urlParams.has('UK')) {
    uk = urlParams.get('UK') as string;
    console.log(uk);
    dispatch(setUserKey(uk));
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<main>Gamification Crif 1987</main>} />
          <Route path="/list" element={<ListQuestionPage />} />
          <Route path="/quiz" >
            <Route
              index
              element={<Navigate replace to="/" />}
            />
            <Route path=":itemId" element={<QuestionPage />}>
            </Route>
            <Route path=":itemId/result" element={<ResultPage />}>
            </Route>
            <Route path=":itemId/thankyou" element={<ThankYouPage />}>
            </Route>
          </Route>
          <Route path="/controlpanel" element={<ControlPanelPage />} ></Route>
          <Route path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { resetQuestionVotes } from '../../../Logic/AWSQuestionsVoteServive';
import { UIConfirm } from '../../../Shared/Confirm/UIConfirm';

type TResetButtonProps = {
    questionId: string;
    questionText?: string | undefined;
};

export const ResetButton: React.FC<TResetButtonProps> = ({ questionId, questionText }) => {
    const [bConfirm, setBConfirm] = useState<boolean>(false);

    const confirmOnClick = async (statoConfirm: boolean) => {
        setBConfirm(false);
        if (statoConfirm && questionId) {
            try {
                await resetQuestionVotes(questionId);
            } catch (ex) {
                console.warn(ex);
            }
        }
    };

    const resetVoteHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setBConfirm(true);
    };

    return (
        <>
            <button className="btn btn-danger" onClick={(e) => resetVoteHandler(e)}>
                Reset
            </button>

            <UIConfirm
                visible={bConfirm}
                title={`Do you want to remove all the votes from "${questionText}"?`}
                buttonConfirmText="Yes"
                buttonCancelText="No"
                onClick={confirmOnClick}
            />
        </>
    );
};
